/**
 *
 * PaymentPromoUy
 *
 */

import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import NewText from 'Components/NewText';
import Button from 'Components/Button';
import Icon from 'Components/Icon';
import Portal from 'Components/Portal';
import Heading from 'Components/Heading';
import masterLogo from '../../Assets/images/masterLogo.png';
import visaLogo from '../../Assets/images/visaLogo.png';
import ocaLogo from '../../Assets/images/ocaLogo.png';
import { cuotasUruguay } from 'Constants';
export const PaymentPromoUy = () => {
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    document.body.classList.toggle('non-scroll', showModal);
    return () => {
      document.body.classList.remove('non-scroll');
    };
  }, [showModal]);
  return (
    //URUGUAY
    <PromoBanner>
      <Container onClick={() => setShowModal(true)}>
        <Logo alt="visa-card" className="logo" src={visaLogo} />
        <Feecontainer>
          <NewText className="font-size"> hasta </NewText>
          <NewText
            className="fees"
            fontSize="36px"
            fontWeight="bold"
            color="orange">
            {cuotasUruguay.visa}
          </NewText>
          <NewText className="font-size"> cuotas </NewText>
        </Feecontainer>
        <Logo alt="master-card" className="logo" src={masterLogo} />
        <Feecontainer>
          <NewText className="font-size"> hasta </NewText>
          <NewText
            className="fees"
            fontSize="36px"
            fontWeight="bold"
            color="orange">
            {cuotasUruguay.master}
          </NewText>
          <NewText className="font-size"> cuotas </NewText>
        </Feecontainer>
        <Logo alt="oca-card" className="logo" src={ocaLogo} />
        <Feecontainer>
          <NewText className="font-size"> hasta </NewText>
          <NewText
            className="fees"
            fontSize="36px"
            fontWeight="bold"
            color="orange">
            {cuotasUruguay.oca}
          </NewText>
          <NewText className="font-size"> cuotas </NewText>
        </Feecontainer>
        <Button className="ver-mas">
          <ButtonLabel>Ver más</ButtonLabel>
        </Button>
      </Container>
      {showModal ? (
        <TermsAndConditions onClose={() => setShowModal(false)} />
      ) : null}
    </PromoBanner>
  );
};
const PromoBanner = styled.div`
  ${({ theme }) => css`
    margin-bottom: ${theme.spacing.three};
    margin-top: ${theme.spacing.three};
  `}
`;

const ButtonLabel = styled.span`
  white-space: nowrap;
`;

const Logo = styled.img`
  ${({ theme }) => css`
	 max-height: 60px;
	 margin-right: ${theme.spacing.two};
	 @media screen and (max-width: 767px) {
		 max-width: 60px;
   `}
`;

const Container = styled.div`
  ${({ theme }) => css`
    margin-bottom: ${theme.spacing.three};
    margin-top: ${theme.spacing.three};
    background-color: ${theme.palette.grays.white};
    border-radius: ${theme.borderRadius.default};
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: ${theme.spacing.four};
    max-width: 1100px;
    margin: auto;
    .fees {
      padding-top: ${theme.spacing.one};
      padding-bottom: ${theme.spacing.one};
    }
    @media screen and (max-width: 767px) {
      position: relative;
      max-width: 95%;
      margin-bottom: ${theme.spacing.four};
      margin-top: ${theme.spacing.four};
      padding: ${theme.spacing.two};
      .ver-mas {
        display: none;
      }
    }
  `}
`;

const Feecontainer = styled.div`
  display: inline-grid;
  text-align: center;
  .font-size {
    @media screen and (max-width: 767px) {
      font-size: 11px;
    }
  }
`;

interface TermsAndConditionsProps {
  onClose: () => void;
}

const paragraphProps = {
  paragraph: true,
  type: 'small' as const
};
const TermsAndConditions = ({ onClose }: TermsAndConditionsProps) => (
  <Portal>
    <ModalOverlay onClick={onClose}>
      <ModalCard onClick={e => e.stopPropagation()}>
        <Close onClick={onClose}>
          <Icon name="mv-cross" size={20} color="default" />
        </Close>
        <Heading type="h4">Condiciones de Financiación:</Heading>
        <NewText {...paragraphProps}>
          Todos los pagos se realizan en dólares estadounidenses (USD). El tipo de cambio para pagos en pesos uruguayos (UYU) es definido por Mevuelo y puede variar sin previo aviso. Los pagos con tarjeta de crédito pueden ser procesados en una o más transacciones ("spliteados"), sumando el monto total, y podrían cargarse contra Mevuelo o directamente contra proveedores como aerolíneas u hoteles.
        </NewText>
        <NewText {...paragraphProps}>
          Mevuelo procesa los pagos con tarjeta de crédito a través de proveedores externos, utilizando únicamente links de pago seguros. Nunca solicitaremos datos de tarjetas de crédito a través de WhatsApp ni telefónicamente. Contamos con certificación PCI DSS, que garantiza los más altos estándares internacionales de seguridad en el procesamiento, almacenamiento y transmisión de datos de pago.
        </NewText>
        <NewText {...paragraphProps}>
          Las condiciones abajo establecidas son específicamente para tarjetas de crédito emitidas en Uruguay. Tarjetas emitidas fuera de Uruguay son procesadas únicamente en 1 cuota.
        </NewText>
        <NewText {...paragraphProps}>
          <strong>Hoteles y Servicios Turísticos No Aéreos:</strong>
          <br />- Visa, Master, OCA: 1 a 6 cuotas sin recargo.
        </NewText>
        <NewText {...paragraphProps}>
          <strong>Vuelos y Paquetes que Incluyen Vuelos:</strong>
          <br />Las cuotas sin recargo tienen dependencia exclusiva de la compañía aérea utilizada. A continuación, se detallan las condiciones específicas por aerolínea y tarjeta:
        </NewText>
        <NewText {...paragraphProps}>
          <strong>Aerolíneas Argentinas:</strong>
          <br />- American Express: 1 cuota. *transacción internacional, puede tener recargo adicional por parte de tu banco emisor.
          <br />- Visa, Master, OCA: 1 a 6 cuotas sin recargo.
          <br />- (Adicionalmente, con Visa se ofrece la opción de hasta 10 cuotas sin recargo, validez hasta el 31/07/2025).
        </NewText>
        <NewText {...paragraphProps}>
          <strong>Air Europa:</strong>
          <br />- American Express: 1 cuota. *transacción internacional, puede tener recargo adicional por parte de tu banco emisor.
          <br />- Visa, Master, OCA: 1 a 12 cuotas sin recargo.
        </NewText>
        <NewText {...paragraphProps}>
          <strong>Air France y Delta:</strong>
          <br />- American Express: 1 cuota. *transacción internacional, puede tener recargo adicional por parte de tu banco emisor.
          <br />- Visa, Master, OCA: 1 cuota sin recargo y, adicionalmente, hasta 6 cuotas con recargo 5%.
        </NewText>
        <NewText {...paragraphProps}>
          <strong>American Airlines:</strong>
          <br />- American Express: 1 cuota. *transacción internacional, puede tener recargo adicional por parte de tu banco emisor.
          <br />- OCA, Master: 1 a 6 cuotas sin recargo.
          <br />- Visa: 1 cuota sin recargo y, adicionalmente, hasta 6 cuotas con recargo 5%.
        </NewText>
        <NewText {...paragraphProps}>
          <strong>Avianca:</strong>
          <br />- American Express y Diners: 1 cuota. *transacción internacional, puede tener recargo adicional por parte de tu banco emisor.
          <br />- OCA, Master: 1 a 6 cuotas sin recargo.
          <br />- Visa: 1 cuota sin recargo y, adicionalmente, hasta 6 cuotas con recargo 5%.
        </NewText>
        <NewText {...paragraphProps}>
          <strong>Copa Airlines:</strong>
          <br />- American Express: 1 cuota. *transacción internacional, puede tener recargo adicional por parte de tu banco emisor.
          <br />- Visa, Master, OCA: 1 a 6 cuotas sin recargo.
        </NewText>
        <NewText {...paragraphProps}>
          <strong>Gol:</strong>
          <br />- American Express: 1 cuota. *transacción internacional, puede tener recargo adicional por parte de tu banco emisor.
          <br />- Visa, Master: 1 a 6 cuotas sin recargo.
          <br />- OCA: 1 cuota sin recargo y, adicionalmente, hasta 6 cuotas con recargo 5%.
        </NewText>
        <NewText {...paragraphProps}>
          <strong>Iberia:</strong>
          <br />- American Express: 1 cuota. *transacción internacional, puede tener recargo adicional por parte de tu banco emisor.
          <br />- Master: 1 a 6 cuotas sin recargo.
          <br />- Visa, OCA: 1 cuota sin recargo y, adicionalmente, hasta 6 cuotas con recargo 5%.
        </NewText>
        <NewText {...paragraphProps}>
          <strong>LATAM:</strong>
          <br />- American Express: 1 cuota. *transacción internacional, puede tener recargo adicional por parte de tu banco emisor.
          <br />- Master: 1 a 6 cuotas sin recargo.
          <br />- Visa, OCA: 1 cuota sin recargo y, adicionalmente, hasta 6 cuotas con recargo 5%.
        </NewText>
        <NewText {...paragraphProps}>
          <strong>Lufthansa:</strong>
          <br />- American Express: 1 cuota. *transacción internacional, puede tener recargo adicional por parte de tu banco emisor.
          <br />- Visa, Master, OCA: 1 cuota sin recargo y, adicionalmente, hasta 6 cuotas con recargo 5%.
        </NewText>
        <NewText {...paragraphProps}>
          <strong>United:</strong>
          <br />- American Express: 1 cuota. *transacción internacional, puede tener recargo adicional por parte de tu banco emisor.
          <br />- Visa, Master, OCA: 1 cuota sin recargo y, adicionalmente, hasta 6 cuotas con recargo 5%.
        </NewText>
        <NewText {...paragraphProps}>
          <strong>Paranair:</strong>
          <br />- Visa, Master, OCA: 1 cuota sin recargo y, adicionalmente, hasta 6 cuotas con recargo 5%.
          <br />- American Express: 1 cuota. *transacción internacional, puede tener recargo adicional por parte de tu banco emisor.
        </NewText>
        <NewText {...paragraphProps}>
          <strong>Sky Airlines:</strong>
          <br />- American Express: 1 cuota. *transacción internacional, puede tener recargo adicional por parte de tu banco emisor.
          <br />- Visa, Master: Hasta 6 pagos sin recargo.
          <br />- OCA: 1 cuota sin recargo y, adicionalmente, hasta 6 cuotas con recargo 5%.
        </NewText>
        <NewText {...paragraphProps}>
          Nota adicional: Para condiciones con otras compañías aéreas o tarjetas, por favor, consulta con tu asesor de Mevuelo.
        </NewText>
      </ModalCard>
    </ModalOverlay>
  </Portal>
);



const ModalOverlay = styled.div`
  min-width: 100vw;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.25);
  ${({ theme }) => css`
    z-index: ${theme.zIndex.modal};
  `}
`;

const ModalCard = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.colors.white};
    padding: ${theme.spacing.four};
    display: flex;
    flex-direction: column;
    position: relative;
    overflow: auto;
    max-height: 80%;
    width: 100%;
    @media screen and (min-width: 768px) {
      max-width: 80%;
      border-radius: ${theme.borderRadius.default};
      min-height: 50vh;
      margin: ${theme.spacing.four};
    }
    @media screen and (max-width: 767px) {
      width: 100%;
      min-height: 100vh;
      padding-top: ${theme.spacing.twelve};
    }
  `}
`;

const Close = styled.div`
  ${({ theme }) => css`
    position: absolute;
    top: ${theme.spacing.two};
    right: ${theme.spacing.two};
    cursor: pointer;
  `}
`;
